import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Invoice_Row } from '../../interfaces/invoicerow';
import { ReservationInvoice } from '../../interfaces/reservation';
import { InvoiceService } from '../../services/invoice.service';
import { ReservationService } from '../../services/reservation.service';
import { AgencyAutocompleteComponent } from '../autocomplete/agency-autocomplete/agency-autocomplete.component';
import { SupplierAutocompleteComponent } from '../autocomplete/supplier-autocomplete/supplier-autocomplete.component';
import { TouroperatorAutocompleteComponent } from '../autocomplete/touroperator-autocomplete/touroperator-autocomplete.component';
import { PassengerComponent } from '../passenger/passenger.component';
import { ServiceComponent } from '../service/service.component';
import { ServiceFormComponent } from '../services-form/services-form.component';


@Component({
  selector: 'app-invoice',
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCard,
    MatCardModule,
    MatIconModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatDatepickerModule,
    ServiceFormComponent,
    PassengerComponent,
    MatSelectModule,
    MatCheckboxModule,
    ServiceComponent,
    MatTooltipModule,
    TouroperatorAutocompleteComponent,
    SupplierAutocompleteComponent,
    AgencyAutocompleteComponent,
    MatTableModule,
  ],
  templateUrl: './invoice.component.html',
  styleUrl: './invoice.component.css'
})
export class InvoiceComponent implements OnInit{

  reservationList: ReservationInvoice[] = [];

  invoiceForm = this.fb.group({
    touroperator_uuid: ["", [Validators.required]],
    agency_uuid: ["", [Validators.required]],
    supplier_uuid: ["", [Validators.required]]
  });

  invocie_row_add: string[] = [];

  displayedColumns: string[] = ['add', 'reservation', 'date', 'price', 'service'];
  dataSource = new MatTableDataSource(this.reservationList);

  readonly range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(private invoiceService: InvoiceService,  private fb: FormBuilder, private reservationService: ReservationService, private _snackBar: MatSnackBar, private router: Router){}

  ngOnInit() {

  }

  getReservations(){
    if(!!this.invoiceForm.value.supplier_uuid && (!!this.invoiceForm.value.agency_uuid || !!this.invoiceForm.value.touroperator_uuid)){
      this.invoiceService.getReservations(this.invoiceForm.value.supplier_uuid!, this.invoiceForm.value.agency_uuid!, this.invoiceForm.value.touroperator_uuid!, "", "", this.range.value.start!, this.range.value.end!, false).subscribe(data => {
        if(data.status == "ok"){
          this.reservationList = data.data;
          this.dataSource = new MatTableDataSource(this.reservationList);
        }
      })
    }else{
      this.openSnackBar("Rellene todos los datos antes de pedir reservas");
    }

  }

  getTouroperators(uuid: string) {
    this.invoiceForm.controls['touroperator_uuid'].patchValue('');
    this.invoiceForm.controls['agency_uuid'].patchValue(uuid);
  }

  assignTtoo(uuid: string) {
    this.invoiceForm.patchValue({
      touroperator_uuid: uuid
    });
  }

  assignS(uuid: string) {
    this.invoiceForm.patchValue({
      supplier_uuid: uuid
    });
  }

  edit(reservation: ReservationInvoice){
    reservation.edit = !reservation.edit;
  }

  editReservation(invoice_row: Invoice_Row){
    if(!invoice_row.invoice_uuid){
      delete invoice_row.invoice_uuid;
    }
    this.reservationService.updateInvoice(invoice_row).subscribe(data => {
      if (data.status == "ok") {

      }
    })
  }

  add(uuid: string){
    let index = this.invocie_row_add.indexOf(uuid);
    if(index == -1){
      this.invocie_row_add.push(uuid);
    }else{
      this.invocie_row_add.splice(index, 1);
    }
  }

  addInvoice(){
    this.invoiceService.addInvoice(this.invocie_row_add).subscribe(data => {
      this.router.navigate(["invoiceList"])
    })
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'x', {
      horizontalPosition: "center",
    });
  }
}
