<div class="hv">
  <mat-accordion class="example-headers-align" multi>
    <ng-container *ngFor="let staff of staffs; let $staffIndex = index">
      <mat-expansion-panel [hideToggle]="staff.user == undefined" [disabled]="staff.user == undefined">
        <mat-expansion-panel-header>
          <mat-panel-title class="fit-content">
            {{staff.name}}
          </mat-panel-title>
          <mat-panel-description>
            <button *ngIf="staff.user?.uuid == null; else active" mat-icon-button matTooltip="Crear usuario" (click)="create(staff.uuid!, $event)">
              <mat-icon fontSet="material-icons-round">person_add</mat-icon>
            </button>
            <ng-template #active>
              <button *ngIf="staff.user?.driver"  mat-icon-button matTooltip="Desactivar usuario" (click)="deactivate(staff.uuid!, $event)">
                <mat-icon fontSet="material-icons-round">person_remove</mat-icon>
              </button>
              <button *ngIf="!staff.user?.driver" mat-icon-button matTooltip="Activar usuario" (click)="activate(staff.uuid!, $event)">
                <mat-icon fontSet="material-icons-round">no_accounts</mat-icon>
              </button>
              <button *ngIf="staff.user?.uuid != null; else active" mat-icon-button matTooltip="Restablecer contraseña" (click)="resetPassword(staff.uuid!, $event)">
                <mat-icon fontSet="material-icons-round">restore</mat-icon>
              </button>
            </ng-template>
            <button mat-icon-button (click)="edit($staffIndex)" matTooltip="Editar personal">
              <mat-icon fontSet="material-icons-round">edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteStaff($staffIndex, $event)"  matTooltip="Borrar personal">
              <mat-icon fontSet="material-icons-round">delete_forever</mat-icon>
            </button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <span mat-subheader>Datos adicionales del usuario</span>
        <mat-list>
          <mat-list-item>
            <mat-icon matListItemIcon>email</mat-icon>
            <div matListItemTitle>Email</div>
            <div matListItemLine>{{staff.user?.email}}</div>      </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>

  <button mat-fab class="corner" matTooltip="Crear personal" matTooltipPosition="left"
    (click)="addStaff()">
    <mat-icon fontSet="material-icons-round">group_add</mat-icon>
  </button>
</div>
