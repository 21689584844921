<mat-dialog-content>
  <form [formGroup]="priceFormArray" class="back">
    <div formArrayName="prices">
      <div *ngFor="let priceGroup of prices.controls; let i = index" class="flexible m-3" [formGroupName]="i">
        <mat-form-field appearance="outline">
          <mat-label>Pax Min:</mat-label>
          <input formControlName="pax_min" matInput type="number">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Pax Max:</mat-label>
          <input formControlName="pax_max" matInput type="number">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Precio:</mat-label>
          <input formControlName="price" matInput type="number">
        </mat-form-field>
        <span class="spacer"></span>
        <button mat-icon-button type="button" (click)="sendPrice(i)"><mat-icon
            fontSet="material-icons-round">{{priceGroup.value.uuid == "" ? "add" : "edit"}}</mat-icon></button>
        <button mat-icon-button  type="button" (click)="removePrice(i)"><mat-icon
            fontSet="material-icons-round">backspace</mat-icon></button>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="corner" mat-button type="button" matTooltip="Crear tarifa" matTooltipPosition="left"
    (click)="addPrice()">Nueva tarifa </button>
</mat-dialog-actions>