<div class="hv">
    <mat-card class="mv">
        <mat-card-header>
            <mat-card-title>Buscador de facturas</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="invoiceForm" class="container">
                <app-supplier-autocomplete class="mat-form-field supplier"
                    [supplier_uuid]="invoiceForm.controls['supplier_uuid'].value!"
                    (selected_supplier)="assignS($event)"></app-supplier-autocomplete>
                <app-agency-autocomplete class="mat-form-field mt-2" [agency_uuid]="invoiceForm.controls['agency_uuid'].value!" (selected_agency)="assignA($event)"></app-agency-autocomplete>
                <mat-form-field *ngIf="invoiceForm.value.invoiced">
                    <mat-label>Fecha</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                      <input matStartDate formControlName="start" placeholder="Fecha Inicio">
                      <input matEndDate formControlName="end" placeholder="Fecha Fin">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  
                    @if (range.controls.start.hasError('matStartDateInvalid')) {
                      <mat-error>Invalid start date</mat-error>
                    }
                    @if (range.controls.end.hasError('matEndDateInvalid')) {
                      <mat-error>Invalid end date</mat-error>
                    }
                  </mat-form-field>
                <div class="mat-form-field cobrar">
                    <span>Facturadas</span>
                    <mat-checkbox class="example-margin" formControlName="invoiced" (change)="getInvoices()"></mat-checkbox>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button class="button" mat-stroked-button type="button" (click)="getInvoices()">
                <mat-icon>search</mat-icon>
                Buscar Facturas
            </button>
        </mat-card-actions>
    </mat-card>

    <mat-card class="mv">
        <mat-card-header>
            <mat-card-title>Facturas</mat-card-title>
        </mat-card-header>
        <mat-card-content class="overflowScroll">
            <table *ngIf="invoiceList.length != 0" mat-table [dataSource]="dataSource" class="">
                <!-- Position Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element"
                    [attr.rowspan]="getRowSpan(element.uuid)"
                    [style.display]="isFirst(element.uuid, element.service_uuid) ? '' : 'none'"
                    [ngClass]="isLast(element.uuid) ? 'last' : ''">
                        <button mat-icon-button *ngIf="!invoiceForm.value.invoiced" type="button" (click)="editInvoice(element)">
                            <mat-icon fontSet="material-icons-round">edit</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="!invoiceForm.value.invoiced" type="button" (click)="generateInvoice(element.uuid)">
                            <mat-icon fontSet="material-icons-round">request_quote</mat-icon>
                        </button>
                        <button mat-icon-button type="button" (click)="downloadInvoice(element)">
                            <mat-icon fontSet="material-icons-round">file_download</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="invoiceForm.value.invoiced" type="button" [matMenuTriggerFor]="menu">
                            <mat-icon fontSet="material-icons-round">email</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item (click)="sendInvoice(element,0)">Proveedor</button>
                          <button mat-menu-item (click)="sendInvoice(element,1)">Cliente</button>
                          <button mat-menu-item (click)="sendInvoice(element,2)">Todos</button>
                        </mat-menu>
                        
                    </td>
                </ng-container>

                <ng-container matColumnDef="invoice">
                    <th mat-header-cell *matHeaderCellDef> Factura </th>
                    <td mat-cell *matCellDef="let element"
                    [attr.rowspan]="getRowSpan(element.uuid)"
                    [style.display]="isFirst(element.uuid, element.service_uuid) ? '' : 'none'"
                    [ngClass]="isLast(element.uuid) ? 'last' : ''"> {{element.id}} </td>
                </ng-container>

                <ng-container matColumnDef="invoice_date">
                    <th mat-header-cell *matHeaderCellDef> Fecha Factura </th>
                    <td mat-cell *matCellDef="let element"
                    [attr.rowspan]="getRowSpan(element.uuid)"
                    [style.display]="isFirst(element.uuid, element.service_uuid) ? '' : 'none'"
                    [ngClass]="isLast(element.uuid) ? 'last' : ''"> 
                    {{element.invoice_date | date:'dd/MM/yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef="supplier">
                    <th mat-header-cell *matHeaderCellDef> Supplier </th>
                    <td mat-cell *matCellDef="let element"
                    [attr.rowspan]="getRowSpan(element.uuid)"
                    [style.display]="isFirst(element.uuid, element.service_uuid) ? '' : 'none'"
                    [ngClass]="isLast(element.uuid) ? 'last' : ''"> {{element.supplier.name}} </td>
                </ng-container>

                <ng-container matColumnDef="client">
                    <th mat-header-cell *matHeaderCellDef> Cliente </th>
                    <td mat-cell *matCellDef="let element"
                    [attr.rowspan]="getRowSpan(element.uuid)"
                    [style.display]="isFirst(element.uuid, element.service_uuid) ? '' : 'none'"
                    [ngClass]="isLast(element.uuid) ? 'last' : ''"> {{element.agency.name}} </td>
                </ng-container>

                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef> PRECIO </th>
                    <td mat-cell *matCellDef="let element"
                    [attr.rowspan]="getRowSpan(element.uuid)"
                    [style.display]="isFirst(element.uuid, element.service_uuid) ? '' : 'none'"
                    [ngClass]="isLast(element.uuid) ? 'last' : ''">{{element.total}}</td>
                </ng-container>

                <ng-container matColumnDef="service">
                    <th mat-header-cell *matHeaderCellDef> Servicios </th>
                    <td mat-cell *matCellDef="let element" [ngClass]="isntFirst(element.uuid, element.service_uuid) ? '' : 'notLast'"> 
                        {{element.description}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Fecha Servicios </th>
                    <td mat-cell *matCellDef="let element" [ngClass]="isntFirst(element.uuid, element.service_uuid) ? '' : 'notLast'">
                        {{element.date | date:'dd/MM/yyyy'}}
                    </td>
                </ng-container>               
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card-content>
    </mat-card>
</div>