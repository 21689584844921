<mat-dialog-content>
  <form [formGroup]="ttooForm" class="back">
    <mat-form-field appearance="outline">
      <mat-label>Agencia</mat-label>
      <input type="text" matInput formControlName="agency" [matAutocomplete]="autoA">
      <mat-autocomplete #autoA="matAutocomplete" [displayWith]="displayAg">
        @for (option of agencyFilteredOptions | async; track option) {
        <mat-option [value]="option">{{option.name}}</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="outline" class="input">
      <mat-label>Touroperador</mat-label>
      <input matInput name="name" formControlName="name" autocomplete="off">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button  (click)="send()" class="ml-2">Añadir</button>
</mat-dialog-actions>
