<mat-dialog-content>
  <form [formGroup]="dateForm">
    <mat-form-field appearance="outline">
      <mat-label>Fecha Nueva</mat-label>
      <input matInput [matDatepicker]="picker" class="date" formControlName="date">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button  (click)="send()" class="ml-2">Añadir</button>
</mat-dialog-actions>
