<mat-dialog-content>
  <form [formGroup]="locationForm" class="back">
    <app-zone-autocomplete (selected_zone)="assignZone($event)"></app-zone-autocomplete>
    <mat-form-field appearance="outline" class="input">
      <mat-label>Localizacion</mat-label>
      <input matInput name="name" formControlName="name" autocomplete="off">
    </mat-form-field>
  </form>
</mat-dialog-content>
<button mat-raised-button  (click)="send()" class="ml-2">Añadir</button>
