<form [formGroup]="touroperatorForm">
    <mat-form-field appearance="outline">
        <mat-label>TOUROPERADOR</mat-label>
        <input type="text" matInput formControlName="touroperator_uuid" [matAutocomplete]="autoT" (click)="clear()">
        <button
          mat-icon-button
          matSuffix
          (click)="clear()"
          [attr.aria-label]="'Clear'"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #autoT="matAutocomplete" [displayWith]="displayT">
          @for (option of touroperatorFilteredOptions | async; track option) {
          <mat-option [value]="option" (onSelectionChange)="send(option.uuid!)">{{option.name}}</mat-option>
          }
      </mat-autocomplete>
      <mat-error >{{ errorMessage('touroperator_uuid','El touroperador es obligatorio') }}</mat-error>
    </mat-form-field>
</form>
