<div class="hv">
    <mat-card class="mv">
        <mat-card-header>
            <mat-card-title>Buscador de reservas</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="invoiceForm" class="container">
                <app-supplier-autocomplete class="mat-form-field mt-2"
                    [supplier_uuid]="invoiceForm.controls['supplier_uuid'].value!"
                    (selected_supplier)="assignS($event)"></app-supplier-autocomplete>
                <app-agency-autocomplete class="mat-form-field mt-2" [agency_uuid]="invoiceForm.controls['agency_uuid'].value!" (selected_agency)="getTouroperators($event)"></app-agency-autocomplete>
                <app-touroperator-autocomplete class="mat-form-field mt-2" [agency_uuid]="invoiceForm.controls['agency_uuid'].value!" [touroperator_uuid]="invoiceForm.controls['touroperator_uuid'].value!"     (selected_touroperator)="assignTtoo($event)"></app-touroperator-autocomplete>
                <mat-form-field>
                    <mat-label>Fecha</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                      <input matStartDate formControlName="start" placeholder="Fecha Inicio">
                      <input matEndDate formControlName="end" placeholder="Fecha Fin">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  
                    @if (range.controls.start.hasError('matStartDateInvalid')) {
                      <mat-error>Invalid start date</mat-error>
                    }
                    @if (range.controls.end.hasError('matEndDateInvalid')) {
                      <mat-error>Invalid end date</mat-error>
                    }
                </mat-form-field>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button class="button" mat-stroked-button type="button" (click)="getReservations()">
                <mat-icon>search</mat-icon>
                Buscar Reservas
            </button>
        </mat-card-actions>
    </mat-card>

    <mat-card class="mv">
        <mat-card-header>
            <mat-card-title>Reservas</mat-card-title>
        </mat-card-header>
        <mat-card-content class="overflowScroll">
            <table *ngIf="reservationList.length != 0" mat-table [dataSource]="dataSource" class="">
                <!-- Position Column -->

                <ng-container matColumnDef="add">
                    <th mat-header-cell *matHeaderCellDef> Añadir </th>
                    <td mat-cell *matCellDef="let element" (click)="add(element.invoice_row_uuid)"> <mat-checkbox class="example-margin"></mat-checkbox> </td>
                </ng-container>

                <ng-container matColumnDef="reservation">
                    <th mat-header-cell *matHeaderCellDef> Referencia </th>
                    <td mat-cell *matCellDef="let element"> {{element.reference}} </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> FECHA </th>
                    <td mat-cell *matCellDef="let element"> {{element.date | date:'dd/MM/yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef> PRECIO </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="!element.edit">{{element.invoice_row.total}}</span> 
                        <!-- <input *ngIf="!!element.edit" class="price" matInput type="number" step="1" [(ngModel)]="element.invoice_row.total"> -->
                    </td>
                </ng-container>

                <ng-container matColumnDef="service">
                    <th mat-header-cell *matHeaderCellDef> Servicio </th>
                    <td mat-cell *matCellDef="let element"> {{element.service[0].description}} </td>
                </ng-container>

               
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card-content>
    </mat-card>

    <button mat-fab class="corner" matTooltip="Crear factura" matTooltipPosition="left" disabled="{{invocie_row_add.length == 0}}"
    (click)="addInvoice()">
    <mat-icon fontSet="material-icons-round">playlist_add</mat-icon>
  </button>
</div>