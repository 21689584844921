import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { map, Observable, startWith, Subscription } from 'rxjs';
import { Agency } from '../../../interfaces/agency';
import { AgencyService } from '../../../services/agency.service';

@Component({
  selector: 'app-agency-autocomplete',
  standalone: true,
  imports: [
    MatInputModule,
    MatAutocompleteModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './agency-autocomplete.component.html',
  styleUrl: './agency-autocomplete.component.css'
})

export class AgencyAutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() agency_uuid: string = "";
  @Output() selected_agency = new EventEmitter<string>();
  agencies: Agency[] = [];
  private agenciesChangedSubscription: Subscription = {} as Subscription;
  agencyFilteredOptions: Observable<Agency[]> = new Observable<Agency[]>();
  public agencyForm: FormGroup = this.fb.group({
    agency_uuid: [""],
  })

  constructor(private agencyService: AgencyService, private fb: FormBuilder) { }

  ngOnInit() {
    this.agencyFilteredOptions = this.agencyForm.controls['agency_uuid'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.agencies;
      }),
    );
    this.getAgencies();
    this.agenciesChangedSubscription = this.agencyService.agenciesChanged.subscribe({next: (net) => {
      if(net){
        this.getAgencies();
      }
    }});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['agency_uuid']) {
      this.getAgencies(true);
    }
  }

  ngOnDestroy(): void {
    if (this.agenciesChangedSubscription) {
      this.agenciesChangedSubscription.unsubscribe();
    }
  }

  getAgencies(search: boolean = false) {
    this.agencyService.getAllAgencies().subscribe(data => {
      this.agencies = data;
      if(search){
        this.agencyForm.patchValue({
          agency_uuid: this.agencies.filter(a => a.uuid == this.agency_uuid)[0]
        });
      }
    })
  }

  display(agency: Agency): string {
    return agency && agency.name ? agency.name : '';
  }

  private _filter(name: string): Agency[] {
    const filterValue = name.toLowerCase();
    return this.agencies.filter(a => a.name?.toLowerCase().includes(filterValue));
  }

  send(uuid: string) {
    this.selected_agency.emit(uuid);
  }

  clear(){
    this.agencyForm.patchValue({
      agency_uuid: ""
    });
    this.selected_agency.emit();
  }

}
