<mat-dialog-content>
  <form [formGroup]="supplierForm">
    <div class="aligned">
      <mat-card class="error" *ngIf="!!error && !!error.error.message">
        <mat-card-content>{{error.error.message}}</mat-card-content>
      </mat-card>
      <mat-form-field appearance="outline" [ngClass]="(!!supplierForm.controls['name'].errors && supplierForm.controls['name'].touched)?'inError':''">
        <mat-label>Nombre</mat-label>
        <input formControlName="name" cdkFocusInitial [readonly]="false" matNativeControl type="text">
        <mat-icon matPrefix fontSet="material-icons-round">text_fields</mat-icon>
        <mat-error >{{ errorMessage('name','El nombre tiene que tener entre 2 y 150 caracteres') }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" [ngClass]="(!!supplierForm.controls['email'].errors && supplierForm.controls['email'].touched)?'inError':''">
        <mat-label>Email</mat-label>
        <input formControlName="email" [readonly]="false" matNativeControl type="text">
        <mat-icon matPrefix fontSet="material-icons-round">format_size</mat-icon>
        <mat-error >{{ errorMessage('email','Tiene que tener el formato nombre@dominio.extension') }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline"  [ngClass]="(!!supplierForm.controls['phone'].errors && supplierForm.controls['phone'].touched)?'inError':''">
        <mat-label>Teléfono</mat-label>
        <input formControlName="phone" [readonly]="false" matNativeControl type="text">
        <mat-icon matPrefix fontSet="material-icons-round">phone</mat-icon>
        <mat-error >{{ errorMessage('phone','El teléfono tiene que tener entre 6 y 12 caracteres') }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" [ngClass]="(!!supplierForm.controls['cif'].errors && supplierForm.controls['cif'].touched)?'inError':''">
        <mat-label>CIF</mat-label>
        <input formControlName="cif" [readonly]="false" matNativeControl type="text">
        <mat-icon matPrefix fontSet="material-icons-round">business</mat-icon>
        <mat-error >{{ errorMessage('cif','El cif tiene que tener entre 5 y 12 caracteres') }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" [ngClass]="(!!supplierForm.controls['city'].errors && supplierForm.controls['city'].touched)?'inError':''">
        <mat-label>Ciudad</mat-label>
        <input formControlName="city" [readonly]="false" matNativeControl type="text">
        <mat-icon matPrefix fontSet="material-icons-round">location_city</mat-icon>
        <mat-error>{{ errorMessage('city','La ciudad tiene que tener entre 1 y 50 caracteres') }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" [ngClass]="(!!supplierForm.controls['postal_code'].errors && supplierForm.controls['postal_code'].touched)?'inError':''">
        <mat-label>Codigo Postal</mat-label>
        <input formControlName="postal_code" [readonly]="false" matNativeControl type="text" role="number" pattern="^[0-9]*">
        <mat-icon matPrefix fontSet="material-icons-round">apartment</mat-icon>
        <mat-error >{{ errorMessage('postal_code','El codigo postal tiene que tener 5 caracteres') }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" [ngClass]="(!!supplierForm.controls['address'].errors && supplierForm.controls['address'].touched)?'inError':''">
        <mat-label>Dirección</mat-label>
        <input formControlName="address" [readonly]="false" matNativeControl type="text">
        <mat-icon matPrefix fontSet="material-icons-round">home</mat-icon>
        <mat-error >{{ errorMessage('address','La dirección tiene que tener entre 1 y 250 caracteres') }}</mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button (click)="send()">Guardar</button>
</mat-dialog-actions>
