<div class="back">
  <span class="ver">v {{ver}}</span>
  <form [formGroup]="formLogin" (ngSubmit)="login()">
    <mat-card>
      <mat-card-title class="primary">Iniciar sesión</mat-card-title>
      <mat-card-content class="formularioLogin">
        <mat-form-field appearance="outline">
          <mat-label>Correo</mat-label>
          <input formControlName="email" matInput type="email" placeholder="yo@dominio.com">
          <mat-error >{{ mailErrorMessage('email','El correo no es válido') }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Contraseña</mat-label>
          <input formControlName="password" matInput type="password">
          <mat-error>{{ mailErrorMessage('password','La contraseña debe tener al menos 6 caracteres') }}</mat-error>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button type="submit" mat-stroked-button >Iniciar sesión</button>
      </mat-card-actions>
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    </mat-card>
  </form>
</div>
