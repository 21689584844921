<div class="hv">
<mat-card>
  <mat-card-header>
    <mat-card-title *ngIf="field != '0'">Pasajeros</mat-card-title>
  </mat-card-header>
  <mat-card-content class="overflowScroll">
    <span *ngIf="passengerList.length == 0" >No hay pasajeros para mostrar</span>

  <table *ngIf="passengerList.length>0" mat-table [dataSource]="dataSource" class="">
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef> HOTEL </th>
      <td mat-cell *matCellDef="let element"> {{private ? element.location_private : element.location?.name}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> NOMBRE </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> Fecha </th>
      <td mat-cell *matCellDef="let element"> {{element.date | date:'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="pax">
      <th mat-header-cell *matHeaderCellDef> Nº PAX </th>
      <td mat-cell *matCellDef="let element"> {{element.pax}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> OBSERVACIONES </th>
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>

    <ng-container matColumnDef="flight">
      <th mat-header-cell *matHeaderCellDef> VUELO </th>
      <td mat-cell *matCellDef="let element"> {{element.flight}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="flightDate">
      <th mat-header-cell *matHeaderCellDef> FECHA </th>
      <td mat-cell *matCellDef="let element"> {{element.flight_date | date:'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="hour">
      <th mat-header-cell *matHeaderCellDef> HORA </th>
      <td mat-cell *matCellDef="let element"> {{element.flight_date | date:'HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="childs">
      <th mat-header-cell *matHeaderCellDef> BB </th>
      <td mat-cell *matCellDef="let element"> {{element.childs}} </td>
    </ng-container>

    <ng-container matColumnDef="babys">
      <th mat-header-cell *matHeaderCellDef> INF </th>
      <td mat-cell *matCellDef="let element">{{element.babies}}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button type="button" (click)="sendPassenger(element)">
          <mat-icon fontSet="material-icons-round">edit</mat-icon>
        </button>
        <button mat-icon-button  type="button" (click)="removePassenger(element)">
          <mat-icon fontSet="material-icons-round">backspace</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</mat-card-content>
<mat-card-actions *ngIf="field != '0'">
  <button mat-stroked-button type="button" (click)="sendPassenger()">
    <mat-icon fontSet="material-icons-round">add</mat-icon> Añadir pasajero
  </button>
</mat-card-actions>
</mat-card>
</div>
