import { AsyncPipe, CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Invoice, ServiceInvoice } from '../../interfaces/invoice';
import { InvoiceService } from '../../services/invoice.service';
import { AgencyAutocompleteComponent } from '../autocomplete/agency-autocomplete/agency-autocomplete.component';
import { SupplierAutocompleteComponent } from '../autocomplete/supplier-autocomplete/supplier-autocomplete.component';
import { PassengerComponent } from '../passenger/passenger.component';
import { ServiceComponent } from '../service/service.component';
import { ServiceFormComponent } from '../services-form/services-form.component';

@Component({
  selector: 'app-invoice-list',
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCard,
    MatCardModule,
    MatIconModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatDatepickerModule,
    ServiceFormComponent,
    PassengerComponent,
    MatSelectModule,
    MatCheckboxModule,
    ServiceComponent,
    MatTooltipModule,
    AgencyAutocompleteComponent,
    SupplierAutocompleteComponent,
    MatTableModule,
    MatMenuModule
  ],
  templateUrl: './invoice-list.component.html',
  styleUrl: './invoice-list.component.css'
})
export class InvoiceListComponent {
  invoiceList: ServiceInvoice[] = [];
  invoiceListCount: Invoice[] = [];
  invoiceForm = this.fb.group({
    agency_uuid: ["", [Validators.required]],
    supplier_uuid: ["", [Validators.required]],
    invoiced: [false]
  });

  invocie_add: string[] = [];

  displayedColumns: string[] = ['invoice', 'invoice_date', 'supplier', 'client', 'price', 'service', 'date', 'actions'];
  dataSource = new MatTableDataSource(this.invoiceList);

  readonly range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(private invoiceService: InvoiceService, public dialog: MatDialog, private fb: FormBuilder, private _snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    this.getInvoices();
  }

  getInvoices() {
    this.invoiceService.getInvoices(this.invoiceForm.value.supplier_uuid!, this.invoiceForm.value.agency_uuid!, this.invoiceForm.value.invoiced!, this.range.value.start!, this.range.value.end!).subscribe(data => {
      if (data.status == "ok") {
        this.invoiceListCount = data.data;
        this.invoiceList = [];
        data.data.forEach(invoice => {
          invoice.invoice_row.forEach(row => {
            row.reservation?.service.forEach(service => {
              let ser : ServiceInvoice = {
                id: invoice.id,
                description: service.description,
                date: service.date,
                total: invoice.total,
                agency: invoice.agency,
                supplier: invoice.supplier,
                invoice_date: invoice.invoice_date,
                uuid: invoice.uuid,
                invoice_row_uuid: row.uuid,
                service_uuid: service.uuid!
              }
              this.invoiceList.push(ser);
            })
          });
        });
        this.dataSource = new MatTableDataSource(this.invoiceList);
      }
      this.dataSource = new MatTableDataSource(this.invoiceList);
    })
  }


  getRowSpan(uuid: string) {
    let inv = this.invoiceListCount.filter(i => i.uuid == uuid)[0];
    return (inv.invoice_row.map(r => r.reservation?.service).length);
  }

  isFirst(uuid: string, service: string) {
    let inv = this.invoiceListCount.filter(i => i.uuid == uuid)[0];
    let ser = inv.invoice_row.flatMap(r => r.reservation?.service!);
    let position = ser.indexOf(ser.filter(s => s.uuid == service)[0]);
    return position == 0;
  }

  isntFirst(uuid: string, service: string) {
    let inv = this.invoiceListCount.filter(i => i.uuid == uuid)[0];
    let ser = inv.invoice_row.flatMap(r => r.reservation?.service!);
    let position = ser.indexOf(ser.filter(s => s.uuid == service)[0]);
    return position == ser.length-1;
  }

  isLast(uuid: string) {
    let inv = this.invoiceListCount.indexOf(this.invoiceListCount.filter(i => i.uuid == uuid)[0]);
    return inv == this.invoiceListCount.length-1;
  }

  assignA(uuid: string) {
    this.invoiceForm.patchValue({
      agency_uuid: uuid
    });
  }

  assignS(uuid: string) {
    this.invoiceForm.patchValue({
      supplier_uuid: uuid
    });
  }

  add(uuid: string) {
    let index = this.invocie_add.indexOf(uuid);
    if (index == -1) {
      this.invocie_add.push(uuid);
    } else {
      this.invocie_add.splice(index, 1);
    }
  }


  editInvoice(serviceInvoice: ServiceInvoice) {
    this.router.navigate(["invoice/"+serviceInvoice.uuid])

  }

  generateInvoice(uuid: string){
    this.invoiceService.generateInvoice(uuid).subscribe(data => {
      if(data.status == "ok"){
        this.invoiceForm.patchValue({
          invoiced: true
        });
        this.getInvoices();
      }
    })
  }

  downloadInvoice(serviceInvoice: ServiceInvoice) {
    let invoice = this.invoiceListCount.filter(i => i.uuid == serviceInvoice.uuid)[0];
    this.invoiceService.download(serviceInvoice.uuid).subscribe(data => {
      let dataType = data.type;
      let binaryData = [];
      binaryData.push(data);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      downloadLink.setAttribute('download', !!serviceInvoice.id ? serviceInvoice.id : "proforma");
      document.body.appendChild(downloadLink);
      downloadLink.click();
    })
  }

  sendInvoice(serviceInvoice: ServiceInvoice, to: number) {
    this.invoiceService.send(serviceInvoice.uuid, to).subscribe(data => {
      this.openSnackBar("Se están enviando los emails");
    });

  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: "center",
      duration: 3000
    });
  }


}
